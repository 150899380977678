@import url(https://fonts.googleapis.com/css?family=Roboto&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

body {
    font-family: 'Roboto', sans-serif !important;
}

.App {
    text-align: center;
}

.App-logo {
    -webkit-animation: App-logo-Scale 1s ease-in-out;
            animation: App-logo-Scale 1s ease-in-out;
    height: 15vmin;
    pointer-events: none;
}

.googlePlayBanner {
    height: 15vmin;
}

.appPreview {
    height: 45vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@-webkit-keyframes App-logo-Scale {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes App-logo-Scale {
    from {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    to {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

