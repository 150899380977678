@import url('https://fonts.googleapis.com/css?family=Roboto&display=swap');

body {
    font-family: 'Roboto', sans-serif !important;
}

.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-Scale 1s ease-in-out;
    height: 15vmin;
    pointer-events: none;
}

.googlePlayBanner {
    height: 15vmin;
}

.appPreview {
    height: 45vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-Scale {
    from {
        transform: scale(0);
    }
    to {
        transform: scale(1);
    }
}
